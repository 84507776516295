<template>
  <div class="order-management">
    <pageBread
      :icon="() => require('../../assets/image/zhuye.png')"
      text="账户安全"
    ></pageBread>
    <!-- <div class="top-order">
      <img class="img" src="" alt="" />
      <span></span>
    </div> -->
    <div class="center-account">
      <h4>修改密码</h4>
      <p>建议您定期修改登录密码以保护账号安全</p>
      <div style="text-align: right">
        <button class="nq-button acc-button" @click="changePassword">
          修改密码
        </button>
      </div>
    </div>
    <div class="center-account">
      <h4>联系方式</h4>
      <p>请填写手机号码，以便在出现问题时我们可以及时联系到您</p>
      <div style="text-align: right">
        <button class="nq-button acc-button" @click="telNum">马上填写</button>
      </div>
    </div>
    <div class="center-account">
      <h4>绑定微信登录</h4>
      <p>绑定{{$baseInfo.COMPANY_TITLE}}公众号"{{$baseInfo.COMPANY_TITLE}}"，通过微信登录{{$baseInfo.COMPANY_TITLE}}更安全方便！</p>
      <div style="text-align: right">
        <button class="nq-button acc-button" @click="binding">马上绑定</button>
      </div>
    </div>
    <nqDialog
      :title="msgTilte"
      :visible="megVisible"
      @close="megVisible = false"
    >
      <!-- 弹框 -->
      <div style="padding: 8px">
        <nqForm
          ref="form"
          @submit="formSubmit"
          :values="formValue"
          :formList="formList"
        ></nqForm>
        <div class="btn">
          <button class="nq-button copy-botton" @click="saveData">确定</button>
          <button class="nq-button" @click="close">关闭</button>
        </div>
      </div>
    </nqDialog>
  </div>
</template>
<script>
import { userSafe } from '../../utils/apis/apis.js'
export default {
  name: 'account',
  data() {
    return {
      showDetail: false,
      msgTilte: '',
      megVisible: false,
      formValue: {
        password: '',
        newPassword: '',
        relPassword: ''
      },
      formList: []
    }
  },

  mounted() {},
  methods: {
    changePassword() {
      this.msgTilte = '修改密码'
      this.formList = [
        {
          label: '原始密码',
          key: 'oldPassWord',
          type: 'input',
          norequire: true,
          placeholder: '请输入原始密码'
        },
        {
          label: '新密码',
          key: 'newPassWord',
          type: 'input',
          placeholder: '请输入新密码'
        },
        {
          label: '确认密码',
          key: 'relPassword',
          type: 'input',
          placeholder: '请再次输入新密码',
          validate: [
            (list, item, index) => {
              return {
                validateRes: list[1].value === item.value,
                message: '两次输入密码不一致'
              }
            }
          ]
        }
      ]
      this.megVisible = true
    },
    telNum() {
      this.msgTilte = '联系方式'
      this.formList = [
        {
          label: '手机',
          key: 'tel',
          type: 'input',
          placeholder: '请输入手机号码'
        }
      ]
      this.megVisible = true
    },
    binding() {
      this.$toast.success('暂未开通')
    },
    close() {
      this.megVisible = false
    },
    saveData() {
      this.$refs.form.submitForm()
    },
    formSubmit(params) {
      if (this.msgTilte === '修改密码') {
        this.$reqPut(userSafe.changePassword, params).then((res) => {
          if (res.data.code === 200) {
            this.$toast.success('修改成功')
          } else {
            this.$toast.error(res.data.message)
          }
        })
      }
      if (this.msgTilte === '联系方式') {
        this.$reqPut(userSafe.contact, params).then((res) => {
          if (res.data.code === 200) {
            this.$toast.success('修改成功')
          } else {
            this.$toast.error(res.data.message)
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import url("../order-management/order.less");
/deep/ .btn {
  display: flex;
  flex-direction: column;
}
/deep/ .copy-botton {
  background: rgb(248, 132, 0);
}
/deep/ .btn .nq-button {
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
}
// /deep/ .close-icon img {
//   display: none;
// }
</style>
